import { gql } from "graphql-tag";

export const REQUEST_CALLBACK = gql`
mutation request_callback($name: String!, $phone: String!, $page_url: String, $First_Visited_URL: String, $pincode: String, $email: String, $User_Device:String, $User_Browser:String ) {
    request_callback(name: $name, phone: $phone, page_url: $page_url, First_Visited_URL : $First_Visited_URL, pincode : $pincode, email: $email,user_device: $User_Device, browser: $User_Browser) {
      status
      msg
    }
  }
  `;
export const GET_IN_TOUCH = gql`
mutation get_in_touch($name: String!, $phone: String!, $pincode: String, $email: String, $page_url: String, $First_Visited_URL: String, $call_id:String, $User_Device:String, $User_Browser:String) {
  get_in_touch(
    name: $name
    phone: $phone
    pincode: $pincode
    email:$email
    page_url:$page_url
    First_Visited_URL : $First_Visited_URL,
    call_id: $call_id
    user_device: $User_Device
    browser: $User_Browser
  ) {
    status
    msg
    lead_id
  }
}`;
export const REQUEST_QUOTE = gql`
mutation request_price_quote($name: String!, $phone: String!, $iama: String!, $email: String!, $areatobetiled: String!, $address1: String!, $address2: String!, $state: String!, $city: String!, $productid: String!, $pincode: String!, $page_url: String!, $First_Visited_URL: String, $User_Device:String, $User_Browser:String) {
  request_price_quote(
    name: $name
    phone: $phone
    iama: $iama
    email: $email
    areatobetiled: $areatobetiled
    address1: $address1
    address2: $address2
    state: $state
    city: $city
    productid: $productid
    pincode: $pincode
    page_url: $page_url
    First_Visited_URL : $First_Visited_URL
    user_device: $User_Device
    browser: $User_Browser
  ) {
    status
    msg
  }
}
`;
export const GET_INVESTORS = gql`
{ 
  investor_page
  {
      section{
          section_name
          title{
              title_name
              financial_year{
                  fytitle
                  fyfile{
                      file_url
                  }
              }
          }
      }
  }
}
`;

export const GET_CMS_PAGES=gql`
query getCmsPage($identifier: String!) {
  cmsPage(identifier: $identifier) {
    identifier
    url_key
    title
    content
    content_heading
    page_layout
    meta_title
    meta_description
    meta_keywords
  }
}
`
export const GET_MEDIA = gql`
{ 
  press_release
  {
      title
      description
      press_release{
          img
          postUrl
          date
          title
      }
      latest_announcement{
          img
          postUrl
          date
          title
          atarget
      }
  }
}
`;

export const GET_LATEST_NEWS = gql`
{ 
  latest_news
  {
      title
      description
      latest_news{
          img
          postUrl
          date
          title
          post_id
          identifier
          publish_time
          thirdurl
          content_heading
          short_content
          content
          meta_keywords
          meta_description
      }
      latest_announcement{
          img
          postUrl
          date
          title
          atarget
      }
  }
}
`;
export const CONTACT_US = gql`
mutation($name: String!, $phone: String!, $email: String!, $state: String, $city: String, $subject: String!, $message: String!, $pageurl: String!, $First_Visited_URL: String, $country:String, $User_Device:String, $User_Browser:String) {
  contact_us(
    name: $name,
     phone: $phone,
     email: $email, 
     state: $state,
     city: $city,
      subject: $subject,
     message: $message,
      pageurl: $pageurl
      country:$country
      First_Visited_URL : $First_Visited_URL
      user_device: $User_Device
      browser: $User_Browser
      ) {
    status
    msg
  }
}
`

export const VIEW_PRESS_RELEASE = gql`
query viewPressRelease($identifier: String!) {
  view_press_release(identifier: $identifier) {
    img
    postUrl
    date
    title
    post_id
    identifier
    authors
    content_heading
    short_content
    content
    meta_keywords
    meta_description
  }
}
`;
export const VIEW_ANNOCEMNT = gql`
query viewAnnouncement($identifier: String!) {
  view_announcement(identifier: $identifier) {
    img
    postUrl
    date
    title
    post_id
    identifier
    content_heading
    short_content
    content
    meta_keywords
    meta_description
  }
}
`;
export const SCHEDULE_MEETING = gql`
mutation ($name: String!, $mobilenumber: String!, $email: String!, $store: String!, $datetimepicker_mask: String!, $youare: String!, $tilesrequirementfor: String!, $remarks: String!, $page_url: String! , $First_Visited_URL: String, $User_Device:String, $User_Browser:String) {
  signature_company(
      name: $name
      mobilenumber: $mobilenumber
      email: $email
      store: $store
      datetimepicker_mask: $datetimepicker_mask
      youare: $youare
      tilesrequirementfor: $tilesrequirementfor
      remarks: $remarks
      page_url: $page_url
      First_Visited_URL : $First_Visited_URL
      user_device: $User_Device
      browser: $User_Browser
  ) {
      status
      msg
  }
}

`;

export const URL_RESOLVER = gql`
query getUrlResolver($url: String!) {
  urlResolver(url: $url) {
    entity_uid
    relative_url
    redirectCode
    type
  }
}
`;

export const GET_AWARDS_AND_ACCOLADES = gql`
query getAwardsAndAccolades{
  
    awards_accolades{
      year_awards{
          year
          awards{
              imagetitle
              imagealt
              image
              title
              content
          
      }
    }
}
}
`

export const GET_CSR_GALLERY= gql`
query getCsrGallery{
  csr_gallery{
      type
      src
      title
  }
}

`

export const GET_BOARD_OF_DIRECTORS_DATA=gql`
query getBoardOfDirectorsData{
  board_of_director{
    image_src
    image_alt
    name
    designation
    details_initial
    details_remaining
}
}
`
export const GET_LEADERSHIP_TEAM_DATA=gql`
query getLeadershipTeamData
{
  executive_managers{
        image_src
        image_alt
        name
        designation
        details_initial
        details_remaining
  }
}
`

export const PREMIUM_SALES_PRODUCT_LIST= gql`
mutation showPremiumSalesProducts(
  $location: String!
  $size: String!
  $row: String!
  $page: String!
  $sortby: String!
) {
showPremiumSalesProducts(
  location: $location
  size: $size
  row: $row
  page: $page
  sortby: $sortby
) {
success
data
message
}
}
`

export const STORE_LOCATOR_SEND_SMS = gql`
mutation SendStoreLocatorSms(
  $name: String!
  $mobileNumber: String!
  $sid: Int!
  $page_url: String!
  $First_Visited_URL: String
  $pincode:String
  $User_Device:String
  $User_Browser:String
) {
  sendStoreLocatorSms(
      name: $name
      mobileNumber: $mobileNumber
      sid: $sid
      page_url: $page_url
      First_Visited_URL : $First_Visited_URL
      pincode:$pincode
      user_device: $User_Device
      browser: $User_Browser
  ) {
    success
    message
  }
}

`
export const TILE_SELECTOR = gql`
mutation getTileSelector(
  $ttype: String!
$mainattrib: String!
$etxraattrib: String!
$showcolor: String
$statevalauto: String
$colorval: String
$listproducts: String
) {
getTileSelector(
  ttype: $ttype
mainattrib: $mainattrib
etxraattrib: $etxraattrib
showcolor: $showcolor
statevalauto: $statevalauto
colorval: $colorval
listproducts: $listproducts
) {
success
data
message
}
}

`
export const GETPRODUCTBYSKU = gql`
mutation getProductDetailsBySku($sku: String!) {
  getProductDetailsBySku(sku: $sku) { 
    success
    data
    message
  }
}
`;
